<template>
  <div class="recharge">
    <template :key="item" v-for="item in rechargeInfoList">
      <div class="item">
        <div class="title">默认账户</div>
        <div class="money">
          <div>总额: {{ item.total }}</div>
          <div>本金: {{ item.capital }}</div>
          <div>赠金: {{ item.handsel }}</div>
        </div>
        <div class="shop">归属门店: {{ item.shop }}</div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    const cardState = useState('order', ['rechargeInfoList'])


    return {
      ...cardState
    }


  }
})
</script>

<style scoped lang="less">
.recharge {
  .item {
    width: 100%;
    height: 142px;
    position: relative;
    background-color: rgba(119, 145, 203, 1);
    border-radius: 5px;
    padding: 20px 20px 10px 20px;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 1);
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .money {
      display: flex;
      margin-top: 24px;
      div {
        margin-right: 20px;
      }
    }
    .shop {
      width: 94.6%;
      position: absolute;
      bottom: 20px;
      font-size: 12px;
      border-top: 1px solid rgba(111, 125, 255, 1);;
      padding-top: 10px;
    }
  }
}
</style>




