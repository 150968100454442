<template>
  <div class="serve">
    <div class="title">
      <span>消费明细(2)</span>
      <span class="other">清空</span>
    </div>
    <div class="list">
      <consume-list :consumeList="orderConsumeInfoList"></consume-list>
      <div @click="handleAddHandselClick" class="add-btn">添加赠送</div>
      <div class="bottom">
        <div class="order-comment">
          <span>订单备注:</span>
          <span class="expand">展开</span>
        </div>
        <div class="pay-btn">
          <div>
            <span>待支付:</span>
            <span class="price">¥188.00</span>
          </div>
          <el-button type="primary">收款</el-button>
        </div>
      </div>
    </div>
    <add-handsel-dialog
      :serveList="orderServeInfoList"
      @changeDialogVisible="handleAddHandelChangeDialogVisible"
      :dialogVisible="addHandeldialogVisible"
    ></add-handsel-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import ConsumeList from './cpns/consume-list/consume-list.vue'
import { useState } from '@/hooks'
import AddHandselDialog from './cpns/add-handsel-dialog/add-handsel-dialog.vue'
export default defineComponent({
  props: {

  },
  components: {
    ConsumeList,
    AddHandselDialog
  },
  setup() {
    const consumeListState = useState('order', ['orderConsumeInfoList','orderServeInfoList'])
    const addHandeldialogVisible = ref(false)
    const handleAddHandselClick = () => {
      addHandeldialogVisible.value = true
    }
    const handleAddHandelChangeDialogVisible = (flag) => {
      addHandeldialogVisible.value = flag
    }
    return {
      ...consumeListState,
      handleAddHandselClick,
      addHandeldialogVisible,
      handleAddHandelChangeDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.serve {
  padding: 20px;
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: rgba(80, 80, 80, 1);
    .other {
      color: rgba(255, 87, 51, 1);
    }
  }
  .list {
    .add-btn {
      height: 280px;
      color: rgba(121, 72, 234, 1);
      font-size: 14px;
      cursor: pointer;
    }
    .bottom {
      .order-comment,
      .pay-btn {
        display: flex;
        justify-content: space-between;
        .price {
          color: rgba(212, 48, 48, 1);
          font-size: 24px;
        }
      }
      .order-comment {
        .expand {
          color: rgba(255, 87, 51, 1);
          font-size: 14px;
        }
        margin-bottom: 20px;
      }
      .pay-btn {
        padding-top: 20px;
        border-top: 1px solid var(--borderColor);
      }
    }
  }
}
</style>




