
<template>
  <div class="recharge">
    <div class="title">
      <span>消费明细(2)</span>
      <span class="empty">清空</span>
    </div>
    <recharge-card></recharge-card>
    <order-collection></order-collection>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import CardType from '../../base-ui/card-type/card-type.vue'
import OrderCollection from '../../base-ui/order-collection/order-collection.vue'
import { useState } from '@/hooks'
import RechargeCard from './cpns/recharge-card/recharge-card.vue'
export default defineComponent({
  props: {

  },
  components: {
    CardType,
    RechargeCard,
    OrderCollection
  },
  setup() {
    const consumeCardState = useState('order', ['consumeStoredValueCardInfoList'])
    return {
      ...consumeCardState
    }

  }
})
</script>

<style scoped lang="less">
.recharge {
  padding-left: 20px;
  .title {
    display: flex;
    justify-content: space-between;
    color: rgba(80, 80, 80, 1);
    font-size: 14px;
    margin-bottom: 20px;
    .empty{
        color: rgba(255, 87, 51, 1);
    }
  }
}
</style>













