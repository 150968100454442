<template>
  <div class="card-type">
    <template :key="item" v-for="item in cardList">
      <div :style="{ backgroundColor: backgroundColor }" class="item">
        <div class="top">
          <div class="name">{{ item.name }}</div>
          <div v-if="isShowNum" class="num">{{ item.num }}次</div>
        </div>
        <div class="center">
          <div class="price">¥{{ item.price.toFixed(2) }}</div>
          <div v-if="item.handsel" class="handsel">赠送:¥{{ item.handsel }}</div>
        </div>
        <div class="bottom">
          <div v-if="item.type == 0" class="type">次卡</div>
          <div v-else-if="item.type == 1" class="type">储值卡</div>
          <div v-else-if="item.type == 2" class="type">定制卡</div>
          <div v-else-if="item.type == 3" class="type">充值</div>
          <div v-if="isShowDetail" class="detail">查看详情</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    isShowNum: {
      type: Boolean,
      default: true
    },
    isShowDetail: {
      type: Boolean,
      default: true
    },
    cardList: {
      type: Array,
      default() {
        return []
      }
    },
    backgroundColor: {
      type: String,
      default: 'rgba(203, 161, 119, 1)'
    }
  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.card-type {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 32%;
    min-width: 220px;
    max-width: 314px;
    height: 142px;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    padding: 20px;
    margin: 0.66%;

    .top {
      display: flex;
      justify-content: space-between;
      .name {
        font-size: 16px;
      }
      .num {
      }
    }
    .center {
      margin-top: 28px;
      font-size: 16px;
      display: flex;
      .price {
        margin-right: 30px;
      }
    }
    .bottom {
      border-top: 1px solid white;
      padding-top: 10px;
      position: absolute;
      width: 88%;
      bottom: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      .type {
      }
      .detail {
      }
    }
  }
}
</style>




