<template>
  <div class="recharge-card">
    <div class="close">
      <el-icon>
        <close />
      </el-icon>
    </div>
    <div class="top">
      <div class="title">
        <div class="default">默认账户</div>
        <div>充值金额</div>
        <div>赠送金额</div>
      </div>
      <div class="top-form">
        <div class="rechareg-obj">
          <span>+ 理疗师/销售</span>
        </div>
        <div>
          <el-input v-model="input" />
        </div>

        <div>
          <el-input v-model="input" />
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title">
        <span>选择充值金额</span>
        <span>管理充值规则</span>
      </div>

      <div class="recharge-amount">
        <el-radio-group v-model="radio1" size="large">
          <el-radio-button label="2000">
            <div class="radio-item">
              <div>2000</div>
              <div>赠送300元</div>
            </div>
          </el-radio-button>
          <el-radio-button label="3000">
            <div class="radio-item">
              <div>3000</div>
              <div>赠送350元</div>
            </div>
          </el-radio-button>
          <el-radio-button label="5000">
            <div class="radio-item">
              <div>5000</div>
              <div>赠送550元</div>
            </div>
          </el-radio-button>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {

    const radio1 = ref('2000')
    return {
      radio1
    }

  }
})
</script>

<style scoped lang="less">
.recharge-card {
  width: 100%;
  height: 260px;
  background-color: rgba(239, 239, 239, 1);
  border-radius: 5px;
  font-size: 14px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  .close {
    position: absolute;
    right: 10px;
    top: 4px;

    i {
      font-size: 24px;
      color: rgba(153, 153, 153, 1);
      cursor: pointer;
    }
  }
  .top {
    .title,
    .top-form {
      display: flex;
      .rechareg-obj {
        color: rgba(80, 80, 80, 1);
        border-radius: 3px;
        font-size: 12px;
        span {
          cursor: pointer;
          padding: 0 16px;
          height: 100%;
          display: flex;
          align-items: center;
          background-color: rgba(255, 255, 255, 1);
          border: 2px dashed rgba(204, 204, 204, 1);
        }
      }
    }
    .title {
      margin-bottom: 16px;
      .default {
        color: rgba(80, 80, 80, 1);
        font-size: 16px;
        font-weight: bold;
      }
      div {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
    .top-form {
      &:deep(.el-input) {
        max-width: 126px;
      }
      & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 20px;
    width: 95%;
    border-top: 1px solid rgba(204, 204, 204, 1);
    .title {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      & > span:nth-child(2) {
        color: rgba(42, 130, 228, 1);
        font-size: 12px;
      }
    }
  }
}
</style>




