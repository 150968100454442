<template>
  <div class="consume-list">
    <template :key="item" v-for="item in consumeList">
      <div class="item">
        <div class="close">
          <el-icon>
            <close />
          </el-icon>
        </div>
        <div class="top">
          <div class="name">{{ item.name }}</div>
          <div class="buy-num">
            <el-input-number v-model="buyNum" :min="1" :max="10" @change="handleChange" />
          </div>
          <div class="price">¥{{ item.price.toFixed(2) }}</div>
        </div>

        <div class="bottom">
          <div class="left">
            <template v-if="item.state == 1">理疗师：{{ item.teacher }}</template>
            <template v-else>
              <div>+ 选择理疗师</div>
            </template>
          </div>
          <div class="right">
            <el-select v-model="value" class="m-2" placeholder="请选择权益" size="large">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select v-model="value" class="m-2" placeholder="请选择充值卡" size="large">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    consumeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  setup() {
    const buyNum = ref(1)

    return {
      buyNum
    }

  }
})
</script>

<style scoped lang="less">
.consume-list {
  margin-top: 20px;
  .item {
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    border-radius: 5px;
    font-size: 14px;
    padding: 20px;
    height: 194px;
    position: relative;
    margin-bottom: 10px;
    .close {
      position: absolute;
      right: 10px;
      top: 4px;

      i {
        font-size: 24px;
        color: rgba(153, 153, 153, 1);
        cursor: pointer;
      }
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .buy-num{
        &:deep(.el-input-number){
          max-width:120px ;
        }
      }
      .name,
      .price {
        color: rgba(80, 80, 80, 1);
        // color: var(--borderColor);
        font-size: 16px;
        font-weight: bold;
        padding-right: 42px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 94%;
      position: absolute;
      bottom: 20px;
      border-top: 1px solid #bfbfbf;
      padding-top: 20px;
      &:deep(.el-select) {
        max-width: 120px;
        margin-left: 10px;
      }
    }
  }
}
</style>

