<template>
  <div class="custom-card">
    <consume-detail-card
      :customCardSelectList="customCardSelectInfoList"
      :isCustomCard="true"
      :consumeList="consumeStoredValueCardInfoList"
    ></consume-detail-card>
    <order-collection></order-collection>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import CardType from '../../base-ui/card-type/card-type.vue'
import ConsumeDetailCard from '../../base-ui/consume-detail-card/consume-detail-card.vue'
import OrderCollection from '../../base-ui/order-collection/order-collection.vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {

  },
  components: {
    CardType,
    ConsumeDetailCard,
    OrderCollection
  },
  setup() {
    const consumeCardState = useState('order', ['consumeStoredValueCardInfoList', 'customCardSelectInfoList'])
    return {
      ...consumeCardState
    }

  }
})
</script>

<style scoped lang="less">
.custom-card {
  padding-left: 20px;
}
</style>









