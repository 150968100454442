<template>
  <div class="serve">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="serve-content">
      <div class="left">
        <serve-list @tabsChange="handleTabsChange"></serve-list>
      </div>
      <div class="right">
        <keep-alive>
          <component :is="currentTabs"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import ServeList from './cpns/serve-list/serve-list.vue'
import CustomCard from './cpns/serve-list/cpns/custom-card/custom-card.vue'
import Goods from './cpns/serve-list/cpns/goods/goods.vue'
import Recharge from './cpns/serve-list/cpns/recharge/recharge.vue'
import Serve from './cpns/serve-list/cpns/serve/serve.vue'
import SecondaryCard from './cpns/serve-list/cpns/secondary-card/secondary-card.vue'
import StoredValueCard from './cpns/serve-list/cpns/stored-value-card/stored-value-card.vue'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    ServeList,
    CustomCard,
    Goods,
    Recharge,
    Serve,
    SecondaryCard,
    StoredValueCard,
  },
  setup() {
    const currentTabs = ref('serve')
    const handleTabsChange = (value) => {
      currentTabs.value = value
    }

    return {
      breadcrumbList,
      handleTabsChange,
      currentTabs
    }

  }
})
</script>

<style scoped lang="less">
.serve {
  .serve-content {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    .left{
      margin-right: 20px;
    }
    .right{
      border-left:1px solid var(--borderColor)
    }
    & > div {
      flex: 1;
    }
  }
}
</style>




