<template>
  <div class="serve-list">
    <el-tabs v-model="serveTabs" type="card" class="tabs" @tab-click="handleTabsClick">
      <div class="search">
        <el-input v-model="searchValue" placeholder="服务/产品名称或编号" />
      </div>
      <el-tab-pane name="billing" label="开单">
        <el-tabs v-model="billingTabs" class="billing-tabs" @tab-click="handleTabsClick">
          <el-tab-pane name="serve" label="服务">
            <serve></serve>
          </el-tab-pane>
          <el-tab-pane name="goods" label="商品">
            <goods></goods>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane name="openCard" label="开卡">
        <el-tabs v-model="openCardTabs" class="open-card-tabs" @tab-click="handleTabsClick">
          <el-tab-pane name="secondaryCard" label="次卡">
            <secondary-card></secondary-card>
          </el-tab-pane>
          <el-tab-pane name="storedValueCard" label="储值卡">
            <stored-value-card></stored-value-card>
          </el-tab-pane>
          <el-tab-pane name="customCard" label="定制卡">
            <custom-card></custom-card>
          </el-tab-pane>
          <el-tab-pane name="recharge" label="充值">
            <recharge></recharge>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Serve from './left-cpns/serve/serve.vue'
import Goods from './left-cpns/goods/goods.vue'
import SecondaryCard from './left-cpns/secondary-card/secondary-card.vue'
import StoredValueCard from './left-cpns/stored-value-card/stored-value-card.vue'
import CustomCard from './left-cpns/custom-card/custom-card.vue'
import Recharge from './left-cpns/recharge/recharge.vue'
export default defineComponent({
  props: {

  },
  components: {
    Serve,
    Goods,
    SecondaryCard,
    StoredValueCard,
    CustomCard,
    Recharge
  },
  emits: ['tabsChange'],
  setup(props, { emit }) {

    const serveTabs = ref('billing')
    const billingTabs = ref('serve')
    const openCardTabs = ref('secondaryCard')
    const searchValue=ref('')
    const handleTabsClick = (e) => {
      emit('tabsChange', e.props.name)

    }
    const handleBillingTabsClick = () => {

    }
    const handleOpenCardTabsClick = () => {

    }

    return {
      serveTabs,
      handleTabsClick,
      handleBillingTabsClick,
      handleOpenCardTabsClick,
      billingTabs,
      openCardTabs,
      searchValue
    }

  }
})
</script>

<style scoped lang="less">
.serve-list {
  position: relative;
  .search {
    position: absolute;
    right: 0;
    z-index: 999;
    top: 0px;
  }
}
</style>




