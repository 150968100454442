<template>
  <div class="serve">
    <el-tabs
      v-model="activeName"
      tab-position="left"
      type="card"
      class="tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane label="所有服务">
        <el-scrollbar height="400px">
          <serve-card :serveList="orderServeInfoList"></serve-card>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane label="放松筋膜">放松筋膜</el-tab-pane>
      <el-tab-pane label="活动促销">活动促销</el-tab-pane>
      <el-tab-pane label="推拿松懈">推拿松懈</el-tab-pane>
      <el-tab-pane label="传统理疗">传统理疗</el-tab-pane>
      <el-tab-pane label="现代理疗">现代理疗</el-tab-pane>
      <el-tab-pane label="康复套餐">康复套餐</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import ServeCard from './cpns/serve-card/serve-card.vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {

  },
  components: {
    ServeCard
  },
  setup() {

    const orderServeListState = useState('order', ['orderServeInfoList'])
    return {
      ...orderServeListState
    }

  }
})
</script>

<style scoped lang="less">
.serve {
  margin-top: 10px;
}
</style>




