<template>
  <div class="stored-value-card">
    <card-type
      :isShowDetail="false"
      :isShowNum="false"
      backgroundColor="rgba(107, 107, 107, 1)"
      :cardList="storedValueCardInfoList"
    ></card-type>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import CardType from '../../base-ui/card-type/card-type.vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {

  },
  components: {
    CardType
  },
  setup() {


    const cardState = useState('order', ['storedValueCardInfoList'])


    return {
      ...cardState
    }

  }
})
</script>

<style scoped lang="less">
.stored-value-card {
}
</style>




