<template>
  <div class="secondary-card">
    <card-type :cardList="secondaryCardInfoList"></card-type>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useState } from '@/hooks'
import CardType from '../../base-ui/card-type/card-type.vue'
export default defineComponent({
  props: {

  },
  components: {
    CardType,

  },
  setup() {
    const cardState = useState('order', ['secondaryCardInfoList'])


    return {
      ...cardState
    }

  }
})
</script>

<style scoped lang="less">
.secondary-card {
}
</style>




