<template>
  <div class="custom-card">
    <card-type
      :cardList="customCardInfoList"
    ></card-type>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import CardType from '../../base-ui/card-type/card-type.vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {

  },
  components: {
    CardType
  },
  setup() {


    const cardState = useState('order', ['customCardInfoList'])


    return {
      ...cardState
    }

  }
})
</script>

<style scoped lang="less">
.custom-card {
}
</style>




