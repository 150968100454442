<template>
  <div class="card">
    <template :key="item" v-for="item in cardList">
      <div class="card-item">
        <div class="name">{{ item.name }}</div>
        <div class="price">¥{{ item.price.toFixed(2) }}</div>
      </div>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    cardList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.card {
  display: flex;
  flex-wrap: wrap;

  .card-item {
    width: 33.33%;
    height: 112px;
    display: flex;
    border: 4px solid white;
    flex-direction: column;
    position: relative;
    padding: 20px;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    border-radius: 5px;
    font-size: 14px;
    .name {
    }
    .price {
      position: absolute;
      bottom: 20px;
      color: rgba(255, 87, 51, 1);
    }
  }
}
</style>




