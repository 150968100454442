export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '服务管理',
    path: ''
  }
]
