<template>
  <div class="goods">
    <el-tabs
      v-model="activeName"
      tab-position="left"
      type="card"
      class="tabs"
      @tab-click="handleClick"
    >
      <el-tab-pane label="所有商品">
        <goods-card :cardList="orderGoodsInfoList"></goods-card>
      </el-tab-pane>
      <el-tab-pane label="香薰类">香薰类</el-tab-pane>
      <el-tab-pane label="精油类">精油类</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import GoodsCard from '../../base-ui/card/card.vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {

  },
  components: {
    GoodsCard
  },
  setup() {

    const orderGoodsInfoState = useState('order', ['orderGoodsInfoList'])
    return {
      ...orderGoodsInfoState
    }

  }
})
</script>

<style scoped lang="less">
.goods {
}
</style>




